/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it


exports.onRouteUpdate = ({ location }) => {
    window.dataLayer.push({
        'event': 'page_view',
        'pathname': location.pathname
    })
}